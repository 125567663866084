<template>
  <v-card>
    <v-card-title class="text-h4 grey lighten-2">
      <v-row>
        <v-col cols="12" lg="6" md="6" sm="12" xs="12">
          <span class="me-2"> User Perfomance </span>
        </v-col>
      </v-row>
    </v-card-title>
    <v-row class="mb-3 my-3 justify-space-between">
      <!-- <v-col cols="12" md="3"> -->
      <v-card-actions>
        <!-- <v-card-title class="align-start">
      <span>Total Operator Perfomance</span>
      <v-spacer></v-spacer>
      <v-btn
        class="me-n3 mt-n2"
        icon
        small
      >
        <v-icon>
          {{ icons.mdiDotsVertical }}
        </v-icon>
      </v-btn>
    </v-card-title> -->
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="dates"
          transition="scale-transition"
          bottom
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <!-- <span class="font-weight-semibold text--primary me-1">{{ $t('Date') }} :</span>
          <span>{{ dateRangeText }}</span> -->
            <v-text-field
              label="User Perfomance"
              :prepend-icon="icons.mdiCalendarMonth"
              :value="dateRangeText"
              readonly
              v-bind="attrs"
              v-on="on"
            >
            </v-text-field>
            <!-- <v-btn
            icon
            small
            v-on="on"
          >
            <v-icon>
              {{ icons.mdiCalendarMonth }}
            </v-icon>
          </v-btn> -->
          </template>
          <v-date-picker v-model="dates" range no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="#ffeeee" @click="menu = false">
              <span class="error--text">{{ $t('cancel') }}</span>
            </v-btn>
            <v-btn text color="success" @click="getOpsStatistic(dates)"> OK </v-btn>
          </v-date-picker>
        </v-menu>
        <v-icon class="ml-2" style="cursor: pointer" @click="resetDates">{{ icons.mdiClose }}</v-icon>
      </v-card-actions>
      <!-- </v-col> -->
      <!-- <v-col cols="12" md="3"> </v-col> -->
    </v-row>

    <div v-if="isLoadingStats" class="loading-indicator">
      <v-progress-circular :size="80" color="primary" indeterminate></v-progress-circular>
    </div>

    <div v-if="!isLoadingStats">
      <template>
        <v-card color="primary">
          <v-tabs v-model="tab" dark background-color="transparent" color="white" show-arrows>
            <v-tabs-slider color="yellow"></v-tabs-slider>

            <v-tab v-for="(data, i) in filterOperator" :key="data.role" :href="'#' + data.role"> {{ data.role }}</v-tab>
          </v-tabs>
        </v-card>
      </template>
      <v-row class="mt-3 mb-3 mx-10">
        <!-- render operator -->
        <v-col v-for="data in filteredDataOperator" :key="data.email" sm="12" md="3" lg="3" cols="12">
          <v-card>
            <v-card-title class="align-start">
              <div class="profile">
                <v-icon left size="50">
                  {{ icons.mdiAccountBox }}
                </v-icon>
                <div class="profile-detail">
                  <span style="margin-top: 5px">{{ data.name }}</span>
                  <span style="font-size: 0.8rem; margin-top: 5px">{{ data.email }}</span>
                </div>
              </div>
              <v-spacer></v-spacer>
              <v-btn class="me-n3 mt-n2" icon small>
                <v-icon>
                  {{ icons.mdiDotsVertical }}
                </v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-list class="pt-0">
                <v-list-item class="d-flex align-center px-0 mt-3">
                  <div class="align-center flex-grow-1 flex-wrap text-no-wrap">
                    <p>Total Replied : {{ data.total_reply }}</p>
                    <p>Average Reply Time : {{ data.avg_reply_time }}</p>
                  </div>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row class="mt-3 mb-3">
        <v-col cols="12">
          <v-card-title class="align-start text-h6 font-weight-semibold">
            <span>User Reply Statistic</span>
          </v-card-title>
          <vue-apex-charts
            type="area"
            height="300"
            :options="chartCreatedAt"
            :series="filteredDataSeriesReply"
          ></vue-apex-charts>
        </v-col>
      </v-row>
      <v-row class="mt-3 mb-3">
        <v-col cols="12">
          <v-card-title class="align-start text-h6 font-weight-semibold">
            <span>User Average Reply Statistic</span>
          </v-card-title>
          <vue-apex-charts
            type="area"
            height="300"
            :options="chartCreatedAtAvg"
            :series="filteredDataAvgReply"
          ></vue-apex-charts>
        </v-col>
      </v-row>
      <v-row class="mb-3 my-3">
        <v-col cols="12" md="8"> </v-col>
        <v-col cols="12" md="3">
          <v-btn v-if="!loading" block color="primary" dense>
            <v-icon dark left>
              {{ icons.mdiExport }}
            </v-icon>
            <vue-excel-xlsx
              :data="filteredDataStatisticDay"
              :columns="columnDataStatistic"
              :file-name="'DataStatistic'"
              :file-type="'xlsx'"
              :sheet-name="'DataStatistic'"
              class="primary-text text-uppercase"
            >
              {{ $t('exportToCsv') }}
            </vue-excel-xlsx>
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mt-3 mb-3">
        <v-col cols="12">
          <v-card-title class="align-start text-h6 font-weight-semibold">
            <span>Table User Reply</span>
          </v-card-title>
          <v-card>
            <v-card-text class="mx-3">
              <v-data-table
                :headers="headers"
                item-key="email"
                :items="filteredDataStatisticDay"
                :items-per-page="10"
                :sort-by.sync="columnName"
                :sort-desc.sync="isDescending"
                :loading="loading"
                multi-sort
                class="text-no-wrap text-center"
                :footer-props="{
                  'items-per-page-text': $t('rowsPerPageLabel'),
                }"
              >
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
/* eslint-disable */
// import PouchDB from 'pouchdb-browser'
import { default as clientPocketBase } from '@/helpers/pocketbase'
import {
  mdiAccountBox,
  mdiCalendarMonth,
  mdiChartBar,
  mdiClose,
  mdiCloseCircle,
  mdiDeleteOutline,
  mdiExport,
  mdiEyeOutline,
  mdiMagnify,
  mdiPencilOutline,
  mdiPlusCircle,
} from '@mdi/js'
import axios from 'axios'
import VueApexCharts from 'vue-apexcharts'
import pouchDbMixin from '../../../mixins/pouchDbMixin'
import { TimeFormater } from './../../../helpers'
// PouchDB.adapter('worker', require('worker-pouch'))
export default {
  name: 'OperatorList',
  components: {
    VueApexCharts,
  },
  mixins: [pouchDbMixin],
  setup() {
    return {
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiPlusCircle,
        mdiExport,
        mdiChartBar,
        mdiEyeOutline,
        mdiCloseCircle,
        mdiPencilOutline,
        mdiCalendarMonth,
        mdiAccountBox,
        mdiClose,
      },
    }
  },
  data() {
    //chart
    return {
      columnName: 'date',
      isDescending: true,
      dataAvgReply: [],
      messageStatistic: [],
      messageStatisticIncoming: [],
      messageStatisticOutcoming: [],
      operatorStatistic: [],
      time: [
        '00:00',
        '01:00',
        '02:00',
        '03:00',
        '04:00',
        '05:00',
        '06:00',
        '07:00',
        '08:00',
        '09:00',
        '10:00',
        '11:00',
        '12:00',
        '13:00',
        '14:00',
        '15:00',
        '16:00',
        '17:00',
        '18:00',
        '19:00',
        '20:00',
        '21.00',
        '22.00',
        '23:00',
      ],
      totalDataOperator: [],
      incomingMessage: 0,
      outgoingMessage: 0,
      totalReplyDay: 0,
      dataStatisticDay: [],
      dataListChannel: [],
      dataListUser: [],
      listUserData: [],
      chartCreatedAt: {},
      chartCreatedAtAvg: {},
      userDataCreated: [],
      menu: false,
      rangeDates: [],
      dates: [this.$moment().subtract(30, 'days').format('YYYY-MM-DD'), this.$moment().format('YYYY-MM-DD')],

      // DIALOG Error Message
      dialogErrorMessage: false,
      errorMessage: '',

      // Confirm delete User
      confirmDeleteMessage: false,
      deleteUserdata: {},

      //loading
      loading: false,

      // SETTING
      columnsSetting: null,
      settingFilterSetting: null,
      tableColumns: [],
      itemsFilter: [],
      // DIALOG Add Operator
      dialogOperator: false,
      model: {
        operatorEmail: '',
      },
      alertText: '',
      queryInstance: '',

      dialogAlert: false,
      // Dialog Add Channel
      dialogSelectChannel: false,
      dataSubId: [],
      dataMenu: [],
      currentItem: {
        role: '',
        auto_assign: false,
        view_assign_only: false,
        limited_channel: false,
        enabled_channel: [],
        limited_menu: false,
        enabled_menu: [],
      },
      isLoadingStats: false,
      columnDataStatistic: [
        {
          label: 'Email',
          field: 'name',
        },
        {
          label: 'Average Reply',
          field: 'avg_reply',
        },
        {
          label: 'Total Replied',
          field: 'count',
        },
        {
          label: 'Date',
          field: 'date',
        },
      ],
      listInstances: [],
      tab: '',
      filterOperator: [],
    }
  },
  computed: {
    filteredDataOperator() {
      return this.totalDataOperator.filter(x => x.role === this.tab)
    },
    filteredDataSeriesReply() {
      console.log(this.tab, this.operatorStatistic, '<<viraa')
      return this.operatorStatistic.filter(x => x.role === this.tab)
    },
    filteredDataAvgReply() {
      return this.dataAvgReply.filter(x => x.role === this.tab)
    },
    filteredDataStatisticDay() {
      return this.dataStatisticDay.filter(x => x.role === this.tab)
    },
    dateRangeText() {
      return this.dates.join(' ~ ')
    },
    // listInstances() {
    //   const inst = this.$store.getters['auth/getListInstance']
    //   if (inst) {
    //     return inst.value
    //   } else {
    //     return []
    //   }
    // },
    listWorkspaces() {
      return this.$store.getters['auth/getListWorkspaces']
    },
    headers() {
      return [
        { text: 'Email', value: 'name', sortable: true },
        { text: 'Average Reply Time', value: 'time', sortable: true, align: 'center' },
        { text: 'Total Replied', value: 'countReply', sortable: true, align: 'center' },
        { text: 'Date', value: 'date', sortable: true, align: 'center' },
      ]
    },
    user() {
      return this.$store.getters['auth/getUser']
    },
    dataListUsers() {
      return this.$store.getters['auth/getListUsers']
    },
    listMenu() {
      return this.user.package === 'basic'
        ? ['Chat', 'Send Broadcast', 'Contact List', 'Channels', 'Broadcasts']
        : this.user.package === 'pro'
        ? ['Chat', 'Send Broadcast', 'Contact List', 'Channels', 'Inbox', 'Broadcasts']
        : this.user.package === 'business'
        ? ['Chat', 'Send Broadcast', 'Contact List', 'Channels', 'Inbox', 'Broadcasts', 'Users']
        : ['Chat', 'Send Broadcast', 'Contact List', 'Channels', 'Inbox', 'Broadcasts', 'Users', 'Business+']
    },
    viewAssign() {
      return this.currentItem.view_assign_only
    },
    limitedChannel() {
      return this.currentItem.limited_channel
    },
    limitedMenu() {
      return this.currentItem.limited_menu
    },
  },
  async mounted() {
    this.loading = true
    const instances = await this.$store.dispatch('auth/getAllInstances')
    this.listInstances = instances?.value || []
    this.setQuery()
    this.getOpsStatistic()
  },
  watch: {
    viewAssign() {
      if (this.currentItem.view_assign_only) {
        this.currentItem.limited_channel = false
        this.currentItem.limited_menu = false
        this.dataMenu = []
        this.dataSubId = []
      }
    },
    limitedChannel() {
      if (!this.currentItem.limited_channel) {
        this.dataSubId = []
      }
    },
    limitedMenu() {
      if (!this.currentItem.limited_menu) {
        this.dataMenu = []
      } else {
        if (this.currentItem.enabled_menu.length === 0) {
          this.dataMenu =
            this.user.package === 'basic'
              ? ['Chat', 'Send Broadcast', 'Contact List', 'Channels', 'Broadcasts']
              : this.user.package === 'pro'
              ? ['Chat', 'Send Broadcast', 'Contact List', 'Channels', 'Inbox', 'Broadcasts']
              : this.user.package === 'business'
              ? ['Chat', 'Send Broadcast', 'Contact List', 'Channels', 'Inbox', 'Broadcasts', 'Users']
              : ['Chat', 'Send Broadcast', 'Contact List', 'Channels', 'Inbox', 'Broadcasts', 'Users', 'Business+']
        }
      }
    },
  },
  methods: {
    resetDates() {
      this.dates = [this.$moment().subtract(30, 'days').format('YYYY-MM-DD'), this.$moment().format('YYYY-MM-DD')]
      this.getOpsStatistic()
    },
    setQuery() {
      let query = ''
      for (let i = 0; i < this.listInstances.length; i++) {
        const instance = this.listInstances[i]
        if (!instance.broadcast_group) {
          if (!query) {
            query = `instance = ${instance._id}`
          } else {
            query += ` OR instance = ${instance._id}`
          }
        }
      }
      this.queryInstance = query
    },
    wrapCsvValue(val, formatFn) {
      let formatted = formatFn !== void 0 ? formatFn(val) : val

      formatted = formatted === void 0 || formatted === null ? '' : String(formatted)

      formatted = formatted.split('"').join('""')

      /**
       * Excel accepts \n and \r in strings, but some other CSV parsers do not
       * Uncomment the next two lines to escape new lines
       */
      // .split('\n').join('\\n')
      // .split('\r').join('\\r')

      return `"${formatted}"`
    },
    exportTableList() {
      // naive encoding to csv format
      //console.log(this.dataStatisticDay, 'this.dataStatisticDay')
      const content = [this.headers.map(col => this.wrapCsvValue(col.text))]
        .concat(
          this.filteredDataStatisticDay.map(row =>
            this.headers
              .map(col => {
                if (col.field === 'channel') {
                  return row.channel
                }
                if (col.field === 'statistic') {
                  return `"sent: ${row.statistic.sent === undefined ? '0' : row.statistic.sent} received: ${
                    row.statistic.received === undefined ? '0' : row.statistic.received
                  } read: ${row.statistic.read === undefined ? '0' : row.statistic.read} replied: ${
                    row.statistic.replied === undefined ? '0' : row.statistic.replied
                  }"`
                }
                return this.wrapCsvValue(row[col.value], undefined)
              })
              .join(','),
          ),
        )
        .join('\r\n')
      const anchor = document.createElement('a')
      anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(content)}`
      anchor.target = '_blank'
      anchor.download = `${new Date()}-${'User Statistic'}.csv`
      anchor.click()
    },

    getOperatorCards(dataOperatorStatistic) {
      // create an empty object to hold the result
      const result = {}

      // iterate over each item in the data array
      dataOperatorStatistic.forEach(item => {
        // extract the email, timestamp, and reply_time values from the item
        const email = item.expand.operator?.email
        if (email) {
          const { timestamp, db_room_id: dbRoomId, reply_time: replyTime } = item
          const date = new Date(timestamp)
          // convert the timestamp to a string in the format "YYYY-MM-DD"
          const dateStr = date.toISOString().substr(0, 10)

          // create an object for the email if it doesn't already exist
          if (!result[email]) {
            result[email] = { totalDbRoomId: 0, totalReply: 0, totalReplies: 0, dbRoomIds: {} }
          }

          // add the db room id and reply time to the object for the email
          result[email].totalDbRoomId += 1
          result[email].totalReply += replyTime

          if (!(dbRoomId in result[email].dbRoomIds) || result[email].dbRoomIds[dbRoomId] !== dateStr) {
            result[email].dbRoomIds[dbRoomId] = dateStr
            result[email].totalReplies += 1
          }
        }
      })

      // calculate the average reply time for each email
      for (const email in result) {
        const { totalDbRoomId, totalReply, totalReplies } = result[email]
        const avgReplyTime = totalReply / totalDbRoomId

        result[email] = {
          totalDbRoomId,
          avgReplyTime,
          totalReplies,
        }
      }
      return result
    },
    async getOpsStatistic() {
      this.$refs.menu.save(this.dates)
      this.isLoadingStats = true

      // create function to genereate unique value
      const makeItUnique = (value, index, self) => self.indexOf(value) === index
      // create function to convert time
      const timeConverter = time => {
        const totalSeconds = time / 1000

        // Calculate the number of hours
        let hours = Math.floor(totalSeconds / 3600)

        // Calculate the number of minutes
        let minutes = Math.floor((totalSeconds % 3600) / 60)

        // Calculate the number of remaining seconds
        let seconds = Math.floor(totalSeconds % 60)

        // Format the time string with hours and minutes formatted with leading zeros if necessary
        return `${hours ? hours + ':' : ''}${minutes.toString().padStart(2, '0')}:${seconds
          .toString()
          .padStart(2, '0')}`
      }

      // create variables to store start and end dates
      let startDate, endDate

      // check if the dates array has two elements
      if (this.dates && this.dates.length === 2) {
        // set start and end date according to which date is earlier
        startDate = new Date(this.dates[0])
        endDate = new Date(this.dates[1])
        if (startDate.getTime() > endDate.getTime()) {
          ;[startDate, endDate] = [endDate, startDate]
        }
      } else {
        // set start and end date based on the first element of the dates array
        startDate = new Date((new Date(this.dates[0]).getTime() / 1000 - 72000) * 1000)
        endDate = new Date((new Date(this.dates[0]).getTime() / 1000 + 72000) * 1000)
      }

      // call the getDateRange function to get the range of dates between start and end date
      this.rangeDates = this.getDateRange(startDate, endDate)

      // initiate operators, dataOperatorStatitic, and series for charts
      let operators
      let dataOperatorStatistic
      let seriesReply = []
      let seriesAvgReply = []
      this.totalDataOperator = []

      // initiate dataReply object
      const dataStatistic = {}

      // fetch data User Statistic
      try {
        dataOperatorStatistic = await clientPocketBase.collection('crm_operator_statistic').getFullList(
          400,
          {
            filter: `sub_id = "${this.user.sub_id}" && (timestamp >= ${new Date(
              new Date(this.dates[0]).setHours(0, 0, 0, 0),
            ).getTime()} && timestamp <= ${new Date(new Date(this.dates[1]).setHours(23, 59, 59, 59)).getTime()})
            && operator.crmRole.role != "Admin"

            `,
            sort: 'timestamp',
            expand: 'operator',
          },
          {
            $$autoCancel: false,
          },
        )
      } catch (error) {
        console.log(error, 'error fetch data User Statistic')
      }

      // iterate over each item in the data array
      dataOperatorStatistic.forEach(item => {
        // extract the email, timestamp, and reply_time values from the item
        const email = item.expand.operator?.email
        if (email) {
          const { timestamp, db_room_id: dbRoomId, reply_time: replyTime } = item
          const date = new Date(timestamp)
          // convert the timestamp to a string in the format "YYYY-MM-DD"
          const dateStr = date.toISOString().substr(0, 10)

          // create an object for the email if it doesn't already exist
          if (!dataStatistic[email]) {
            dataStatistic[email] = {}
          }

          // create an object for the date if it doesn't already exist
          if (!dataStatistic[email][dateStr]) {
            dataStatistic[email][dateStr] = {}
          }

          // create an array for the dbRoomId if it doesn't already exist
          if (!dataStatistic[email][dateStr][dbRoomId]) {
            dataStatistic[email][dateStr][dbRoomId] = []
          }

          // add the reply_time to the array for the dbRoomId and date
          dataStatistic[email][dateStr][dbRoomId].push(replyTime)
        }
      })

      // transform datastatistic
      for (const email in dataStatistic) {
        const dates = dataStatistic[email]

        for (const date in dates) {
          const dbRooms = dates[date]

          let totalCountDbRoomId = 0
          let totalAvgReply = 0

          for (const dbRoom in dbRooms) {
            const replyTimes = dbRooms[dbRoom]

            // calculate the average reply time for this db room id
            const sum = replyTimes.reduce((a, b) => a + b, 0)
            const avgReplyTime = sum / replyTimes.length

            totalCountDbRoomId++
            totalAvgReply += avgReplyTime

            // replace the array with the average reply time
            dbRooms[dbRoom] = avgReplyTime
          }

          // update the data structure with total count of dbRoom and total average reply time
          const dateObj = { totalCountDbRoomId, totalAvgReply: totalAvgReply / totalCountDbRoomId }
          dates[date] = dateObj
        }
      }

      // get data for operator cards
      const cards = this.getOperatorCards(dataOperatorStatistic)

      // fetch operators on current sub id
      try {
        const endpoint = `${process.env.VUE_APP_WA_SERVICES_URL}statistic/user`

        const body = {
          sub_id: this.user.sub_id,
        }

        const { data: listUsers } = await axios.post(endpoint, body)
        operators = listUsers.data
      } catch (error) {
        console.log(error, 'error fetching operators')
      }

      // iterate over operators data
      for (let i = 0; i < operators.length; i++) {
        const operator = operators[i]
        const tempOperator = {
          name: operator.name,
          email: operator.email,
          role: operator.role,
          total_reply: 0,
          avg_reply_time: 0,
        }

        // create operators card

        // iterate over cards object
        for (const key in cards) {
          // check if operator email exists in the cards
          if (operator.email === key) {
            tempOperator.total_reply = cards[key].totalReplies
            tempOperator.avg_reply_time = timeConverter(cards[key].avgReplyTime)
          }
        }

        // if (this.totalDataOperator.findIndex(ele => ele.email === operator.email) === -1) {
        // }
        this.totalDataOperator.push(tempOperator)

        // create series reply
        const tempReply = {
          name: operator.email,
          role: operator.role,
          data: [],
        }
        // create series avg reply
        const tempAvgReply = {
          name: operator.email,
          role: operator.role,
          data: [],
        }

        // iterate over dataStatistic object
        for (const key in dataStatistic) {
          // check email operator
          if (operator.email === key) {
            // iterate over range date
            for (let i = 0; i < this.rangeDates.length; i++) {
              const date = this.rangeDates[i]
              // if current operator has property date, push the value
              if (dataStatistic[key].hasOwnProperty(date)) {
                tempReply.data.push(dataStatistic[key][date].totalCountDbRoomId)
                tempAvgReply.data.push(dataStatistic[key][date].totalAvgReply)
                dataStatistic[key][date].role = operator.role
              } else {
                tempReply.data.push(0)
                tempAvgReply.data.push(0)
              }
            }
          }
        }
        if (tempOperator.total_reply === 0) {
          for (let i = 0; i < this.rangeDates.length; i++) {
            tempReply.data.push(0)
            tempAvgReply.data.push(0)
          }
        }
        // push temp to series
        seriesReply.push(tempReply)
        seriesAvgReply.push(tempAvgReply)
      }

      const uniqueRoles = this.totalDataOperator.filter((item, index) => {
        const currentIndex = this.totalDataOperator.findIndex(el => el.role === item.role)
        return currentIndex === index
      })

      // console.log(dataStatistic, '<ini apa')
      this.filterOperator = uniqueRoles
      const rangeDatesLocal = this.rangeDates

      // total reply chart
      const chart = {
        chart: {
          stacked: true,
          toolbar: {
            show: true,
            tools: {
              download: true,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
              customIcons: [],
            },
          },
        },

        tooltip: {
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            const hoverXaxis = w.globals.seriesX[seriesIndex][dataPointIndex]
            const hoverIndexes = w.globals.seriesX.map(seriesX => {
              return seriesX.findIndex(xData => xData === hoverXaxis)
            })
            let total = 0

            for (let i = 0; i < series.length; i++) {
              const oneSeries = series[i]

              total += oneSeries[dataPointIndex]
            }

            let hoverList = ''
            hoverIndexes.forEach((hoverIndex, seriesEachIndex) => {
              if (hoverIndex >= 0) {
                hoverList += `
                        <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">

                            <span class="apexcharts-tooltip-marker" style="background-color: ${
                              w.globals.markers.colors[seriesEachIndex]
                            };"></span>
                            <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                                <div class="apexcharts-tooltip-y-group">
                                    <span class="apexcharts-tooltip-text-y-label">${
                                      w.globals.seriesNames[seriesEachIndex]
                                    }: </span>
                                    <span class="apexcharts-tooltip-text-y-value">${w.globals.yLabelFormatters[0](
                                      series[seriesEachIndex][hoverIndex],
                                    )}</span>
                                </div>
                            </div>
                        </div>`
              }
            })
            //
            return `<div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">${
              rangeDatesLocal[hoverXaxis - 1]
            } TOTAL: ${total}</div>${hoverList}`
          },
        },

        colors: ['#008FFB', '#00E396', '#CED4DC', '#ff4c51', '#ffb400', '#1F979E'],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
        },
        fill: {
          type: 'gradient',
          gradient: {
            opacityFrom: 0.6,
            opacityTo: 0.8,
          },
        },
        legend: {
          position: 'top',
          horizontalAlign: 'left',
        },
        xaxis: {
          categories: JSON.parse(JSON.stringify(this.rangeDates)),
        },
      }

      // average reply chart
      const chartAvg = {
        chart: {
          stacked: true,
          toolbar: {
            show: true,
            tools: {
              download: true,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
              customIcons: [],
            },
          },
        },
        colors: ['#008FFB', '#00E396', '#CED4DC', '#ff4c51', '#ffb400', '#1F979E'],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
        },
        fill: {
          type: 'gradient',
          gradient: {
            opacityFrom: 0.6,
            opacityTo: 0.8,
          },
        },
        legend: {
          position: 'top',
          horizontalAlign: 'left',
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return timeConverter(value)
            },
          },
        },
        xaxis: {
          categories: JSON.parse(JSON.stringify(this.rangeDates)),
        },
      }

      // initiate table statistic
      const tableStatistic = []

      // iterate over data statistic to create table statistic
      for (const email in dataStatistic) {
        const dates = dataStatistic[email]
        for (const date in dates) {
          tableStatistic.push({
            name: email,
            role: dates[date].role,
            time: timeConverter(dates[date].totalAvgReply),
            countReply: dates[date].totalCountDbRoomId,
            date: date,
          })
        }
      }
      console.log(seriesReply, 'opo iki<')
      // assign to local states
      this.chartCreatedAt = chart
      this.chartCreatedAtAvg = chartAvg
      this.operatorStatistic = JSON.parse(JSON.stringify(seriesReply))
      this.dataAvgReply = JSON.parse(JSON.stringify(seriesAvgReply))
      this.dataStatisticDay = tableStatistic
      this.loading = false
      this.menu = false
      this.isLoadingStats = false
    },
    async getStatisticChart(date) {
      if (date && date.length === 2) {
        if (new Date(date[0]).getTime() > new Date(date[1]).getTime()) {
          this.rangeDates = this.getDateRange(new Date(date[1]), new Date(date[0]))
        } else {
          this.rangeDates = this.getDateRange(new Date(date[0]), new Date(date[1]))
        }
      } else {
        const startDate = `${new Date((new Date(date[0]).getTime() / 1000 - 72000) * 1000).getFullYear()}-${
          new Date((new Date(date[0]).getTime() / 1000 - 72000) * 1000).getMonth() + 1
        }-${new Date((new Date(date[0]).getTime() / 1000 - 72000) * 1000).getDate()}`
        const endDate = `${new Date((new Date(date[0]).getTime() / 1000 + 72000) * 1000).getFullYear()}-${
          new Date((new Date(date[0]).getTime() / 1000 + 72000) * 1000).getMonth() + 1
        }-${new Date((new Date(date[0]).getTime() / 1000 + 72000) * 1000).getDate()}`

        this.rangeDates = this.getDateRange(new Date(startDate), new Date(endDate))
      }
      this.dates = date
      const statOps = await this.$store.dispatch('rooms/statisticOperator', {
        subId: this.user.sub_id,
        dateRange: this.rangeDates,
      })
      const chart = {
        chart: {
          toolbar: {
            show: true,
            tools: {
              download: true,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
              customIcons: [],
            },
          },
        },
        legend: {
          show: true,
          position: 'top',
          horizontalAlign: 'left',
          fontSize: '14px',
          fontFamily: '"Inter", sans-serif',
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: false,
          curve: 'straight',
        },
        colors: [
          '#ab7efd',
          '#F0D9FF',
          '#e0cffe',
          '#BFA2DB',
          '#b992fe',
          '#b391ec',
          '#b9a3dc',
          '#bdb5ca',
          '#bec6b9',
          '#8c62dd',
          '#966be8',
          '#a175f2',
          '#b588ff',
          '#c091ff',
          '#ca9bff',
          '#b089f3',
          '#b594e8',
          '#b89fde',
          '#bba9d4',
          '#bdb4c9',
          '#bebebe',
          '#bb93fe',
          '#caa8ff',
          '#d9beff',
          '#e6d3ff',
          '#f3e9ff',
          '#ffffff',
          '#8d68ce',
          '#6f53a1',
          '#533f76',
          '#392c4e',
        ],
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        fill: {
          opacity: 1,
          type: ['solid', 'gradient'],
        },
        xaxis: {
          categories: [],
        },
      }
      // this.userDataCreated = JSON.parse(JSON.stringify(statOps.data.data ? statOps.data.data : []))
      const range = JSON.parse(JSON.stringify(this.rangeDates))
      this.chartCreatedAt = chart

      this.chartCreatedAt.xaxis.categories = range
      this.menu = false

      //console.log(statOps, 'aaaaaaaaa')
    },
    getDateRange(startDate, endDate) {
      const dates = []
      let currentDate = startDate
      const addDays = function (days) {
        const date = new Date(this.valueOf())
        date.setDate(date.getDate() + days)

        return date
      }
      while (currentDate <= endDate) {
        dates.push(new Date(currentDate).toISOString().substring(0, 10))
        currentDate = addDays.call(currentDate, 1)
      }

      // //console.log(dates, 'oooooooooooo')
      return dates
    },
    async closeDialog() {
      this.dialogSelectChannel = false
      this.loading = true
      await this.$store
        .dispatch('auth/fetchListUser', {
          sub_id: this.user.sub_id,
        })
        .then(data => {
          this.loading = false
        })
    },
    async saveChannel() {
      this.dialogSelectChannel = false
      if (this.currentItem.view_assign_only) {
        this.currentItem.limited_channel = false
        this.currentItem.limited_menu = false
      }
      this.currentItem.enabled_channel = []
      if (this.currentItem.limited_channel) {
        this.dataSubId.forEach((data, index) => {
          if (data) {
            this.currentItem.enabled_channel.push(this.listInstances[index]._id)
          }
        })
      }
      this.currentItem.enabled_menu = []
      if (this.currentItem.limited_menu) {
        this.dataMenu.forEach((data, index) => {
          if (data) {
            this.currentItem.enabled_menu.push(this.listMenu[index])
          }
        })
      }
      var res = await this.$store.dispatch('auth/editUserSubscription', {
        id: this.currentItem.id,
        auto_assign: this.currentItem.auto_assign,
        view_assign_only: this.currentItem.view_assign_only,
        limited_channel: this.currentItem.limited_channel,
        enabled_channel: this.currentItem.enabled_channel,
        limited_menu: this.currentItem.limited_menu,
        enabled_menu: this.currentItem.enabled_menu,
      })
      if (!res.status) {
        this.errorMessage = res.errorMessage
        this.dialogErrorMessage = true
      }
      await this.$store.dispatch('auth/fetchListUser', {
        sub_id: this.user.sub_id,
      })
      await this.$store.dispatch('auth/getChannelAccess', {
        sub_id: this.user.sub_id,
        email: this.user.email,
        token: this.user.token,
      })
    },
    getChannelsName(id) {
      const obj = this.listInstances.find(x => x._id === id)
      if (obj) {
        return (
          (obj.label.startsWith('-') || obj.label.includes('Default') || obj.label.includes('62')
            ? obj.label_server
            : obj.label) +
          ' (' +
          obj.phone_number +
          ')'
        )
      }
      return ''
    },
    async addSelectedChannel(item) {
      this.dialogSelectChannel = true
      this.currentItem = {
        ...item,
      }
      this.listInstances.forEach((instance, index) => {
        this.dataSubId[index] = item.enabled_channel && item.enabled_channel.findIndex(x => x === instance._id) > -1
      })
      this.listMenu.forEach((menu, index) => {
        this.dataMenu[index] = item.enabled_menu && item.enabled_menu.findIndex(x => x === menu) > -1
      })
    },
    async addOperator() {
      let limitOps = await this.$store.dispatch('subscriptions/getSubscriptionInfo', this.user)
      // //console.log(limitOps,"aaaaaaaaaaaaaaaaaa")
      if (this.dataListUsers.length < limitOps.data.data.dataValues.max_operator) {
        this.dialogOperator = true
      } else {
        this.showAlert(this.$t('warningmaxoperator'))
      }
    },
    showAlert(warningText) {
      this.alertText = warningText
      this.dialogAlert = true
    },
    msToTime(duration) {
      return TimeFormater.msToTime(duration)
    },
    validateEmail(email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    },
    async saveUser() {
      if (this.validateEmail(this.model.operatorEmail)) {
        const currentWorkspace = this.listWorkspaces.find(
          workspace => workspace.role === 'Admin' && workspace.sub_id === this.user.sub_id,
        )
        //console.log(currentWorkspace, this.listWorkspaces, `INI DIA`)
        var res = await this.$store.dispatch('auth/addUser', {
          email: this.model.operatorEmail,
          sub_id: this.user.sub_id,
          role: 'Operator',
          auto_assign: false,
          view_assign_only: false,
          limited_channel: false,
          enabled_channel: [],
          workspace_name: currentWorkspace.workspace_name,
        })
        if (res.status) {
          await this.$store.dispatch('auth/fetchListUser', {
            sub_id: this.user.sub_id,
          })
        } else {
          this.errorMessage = res.errorMessage
          this.dialogErrorMessage = true
        }
        this.model.operatorEmail = ''
        this.dialogOperator = false
      } else {
        this.errorMessage = 'Please Input in email Format'
        this.model.operatorEmail = ''
        this.dialogErrorMessage = true
        this.dialogOperator = false
      }
    },
    async deleteUser(data) {
      this.loading = true
      var res = await this.$store.dispatch('auth/deleteUser', {
        email: data.email,
        sub_id: data.sub_id,
      })
      if (res.status) {
        await this.$store.dispatch('auth/fetchListUser', {
          sub_id: this.user.sub_id,
        })
      } else {
        this.errorMessage = res.errorMessage
        this.dialogErrorMessage = true
      }
      this.loading = false
      this.deleteUserdata = {}
      this.confirmDeleteMessage = false
    },
  },
  created() {},
}
</script>

<style lang="scss" scoped>
.profile {
  display: flex;
}
.loading-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-bottom: 15px;
}
.profile-detail {
  display: flex;
  flex-direction: column;
}
.table-broadcast ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
.chip-margin-left {
  margin-left: 1px;
}
.search-in-detail-status {
  margin-right: 5px;
  margin-bottom: 5px;
}
.bottom-action {
  display: none;
}
@media (max-width: 536px) {
  .hide-buton {
    display: none;
  }
  .bottom-action {
    display: inherit;
  }
  .block-condition {
    display: flex;
    flex: 1 0 auto;
    min-width: 100% !important;
  }
}
</style>
